.c-notification {
  position: relative;
  border-radius: 1rem;
  padding: 1rem 3rem 1rem 2rem;
  background-color: $clr-secondary;
  margin-bottom: 1rem;
  border: 1px solid $clr-quaternary;

  h6 {
    color: $clr-primary;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.9rem;
  }
  p {
    margin: 0;
    font-size: 1.5rem;
  }
  button {
    padding: 0;
    @include square-dimensions(2.5rem);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2rem;
    margin: auto;
    border: none;
    background-color: transparent;
    img {
      max-width: 1.5rem;
    }
  }

  &--red {
    background-color: $clr-tertiary;
    border: 1px solid $clr-tertiary;
    h6, p {
      color: $clr-secondary;
    }
  }

  &--yellow {
    background-color: #ffc106;
    border: 1px solid #ffc106;
    h6, p {
      color: #000000;
    }
  }

  &--green {
    background-color: mediumseagreen;
    border: 1px solid mediumseagreen;
    h6, p {
      color: $clr-secondary;
    }
  }
}
