.c-tab {
  &__header {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom: 1px solid $clr-quaternary;
    background-color: $clr-secondary;
    padding: 0 3rem;
    button {
      background-color: transparent;
      border: none;
      padding: 1.5rem 0 1.3rem;
      border-bottom: 3px solid transparent;
      height: 100%;
      margin-right: 2rem;
      font-size: 1.5rem;
      font-weight: 300;
    }
    .isActive {
      font-weight: 600;
      color: $clr-tertiary;
      border-bottom: 3px solid $clr-tertiary;
    }
  }
  &__percentage {
    width: 19rem;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 500px) {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }

    label {
      font-size: 1.2rem;
      text-align: right;
      margin-right: 1rem;
      margin-bottom: 0;

      @media screen and (max-width: 500px) {
        font-size: 1.5rem;
        margin-bottom: .5rem;
      }
    }
    small {
      position: absolute;
      top: 1.7rem;
      right: 1.4rem;
      pointer-events: none;

      @media screen and (max-width: 500px) {
        top: initial;
        bottom: 2rem;
        right: 2rem;
      }
    }
    input {
      width: 10rem;
      color: $clr-primary !important;

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
    input:focus ~ small {
      opacity: 0;
    }
    input:hover ~ small {
      opacity: 0;
    }
  }
  &__content {
    input {
      color: $clr-primary !important;
    }
    &__primary {
      background-color: $clr-secondary;
      padding: 2.5rem 3rem 3rem;
      header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 500px) {
          flex-direction: column;
          align-items: flex-start;
        }

        h3 {
          font-size: 2.2rem;
          font-weight: 600;
          margin: 0;

          @media screen and (max-width: 500px) {
            margin-bottom: 1rem;
          }
        }

      }
    }
    &__secondary {
      background-color: $clr-secondary;
      padding: 2.5rem 3rem 3rem;
      border-top: 1px solid #D3E0E5;
      margin-left: 1rem;

      &:last-of-type {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }

      header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 500px) {
          flex-direction: column;
          align-items: flex-start;
        }

        h3 {
          font-size: 2.2rem;
          font-weight: 600;
          margin: 0 0 0 2rem;
          word-break: break-word;
          position: relative;

          @media screen and (max-width: 500px) {
            margin: 0 0 1rem 2rem;
          }

          &:before {
            position: absolute;
            top: .5rem;
            left: -2rem;
            content: "";
            @include square-dimensions(1rem);
            border-left: 2px solid $clr-tertiary;
            border-bottom: 2px solid $clr-tertiary;
          }
        }

      }
    }
  }
}
