.c-pagination {
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    @include square-dimensions(3.3rem);
    border-radius: .6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    margin-right: .7rem;

    &:hover{
      cursor: pointer;
    }
  }

  span {
    line-height: 1;
    font-size: 1.5rem;
  }
  small {
    margin-right: .7rem;
  }
  .isActive {

    a {
      background-color: $clr-primary;
      border: 1px solid $clr-primary;
      color: $white-primary;
      font-weight: 700;

    }
  }
}
