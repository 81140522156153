.c-tag {
  border-radius: 10rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid transparent;
  font-weight: 500;

  &--small {
    padding: .2rem .75rem;
    font-size: .9rem;
  }

  &--black {
    background-color: $clr-primary;
    color: $clr-quaternary;
  }
  &--blue {
    background-color: #D3E0E5;
    color: $clr-primary;
  }
  &--outline {
    border: 1px solid $clr-primary;
  }
}
