/*------------------------------------*\
  #GLOBAL STYLES
\*------------------------------------*/

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 190%;

  @media screen and (max-width: 3840px) {
    font-size: 95%;
  }
  @media screen and (max-width: 3650px) {
    font-size: 90%;
  }
  @media screen and (max-width: 3350px) {
    font-size: 85%;
  }
  @media screen and (max-width: 2900px) {
    font-size: 75%;
  }
  @media screen and (max-width: 2560px) {
    font-size: 70%;
  }
  @media screen and (max-width: 2048px) {
    font-size: 65%;
  }
  @media screen and (max-width: 1920px) {
    font-size: 62.5%;
  }
  @media screen and (max-width: 1350px) {
    font-size: 53%;
  }
  @media screen and (max-width: 1023px) {
    font-size: 57%;
  }
  @include mq-tablet {
    font-size: 54%;
  }
  @include mq-mobile {
    font-size: 48%;
  }
}

::selection {
  background-color: $clr-primary;
  color: $clr-secondary;
}

body {
  overflow-x: hidden;

  @media screen and (max-width: 1100px) {
    padding-top: 75px;
  }
}

select::-ms-expand {
  display: none;
}

::-webkit-scrollbar {
  background-color: lightgray;
  width: 1rem;
}

::-webkit-scrollbar-thumb {
  background-color: #979391;
}

// img reset
img {
  max-width: 100%;
  height: auto;
}

// basic hr
hr,
.hr {
  display: block;
  height: 1px;
  border: 0;
  margin: 0;
  padding: 0;
}

figure {
  margin: 0;
}
