.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  opacity: 0;
  pointer-events: none;

  &__question {
    padding-bottom: 1.5rem;

    &__bool-radios {
      display: flex;
      align-items: center;

      .c-form__group{
        display: inline-flex;
        width: auto;
        margin-right: 1.5rem;
        align-items: center;

        input{
          position: relative;
          top: .2rem;
          margin-right: .5rem;
        }
      }
    }
    p {
      margin-bottom: .8rem;
    }

  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
  }

  &__content {
    background-color: $clr-secondary;
    position: relative;
    z-index: 5;
    max-width: 80rem;
    width: 80rem;
    border-radius: 1rem;
  }

  &__header {
    padding: 3rem 0 1rem 3rem;
    max-width: calc(100% - 5rem);

    h1 {
      font-size: 2.3rem;
      color: $clr-primary;
      font-weight: 500;
    }
  }

  &__body {
    padding: 0 3rem 3rem;
  }

  &__close {
    position: absolute !important;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    @include square-dimensions(5rem);
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 2rem;
    }
  }
}

.js-modalActive {
  opacity: 1;
  pointer-events: all;
}
