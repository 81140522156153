/*------------------------------------*\
  #COLORS
\*------------------------------------*/

// Colors names
$black-primary: #0E1427;
$white-primary: #ffffff;
$red-primary: #E14343;
$blue-primary: #EEF4F6;

// Main colors
$clr-primary: $black-primary;
$clr-secondary: $white-primary;
$clr-tertiary: $red-primary;
$clr-quaternary: $blue-primary;

/*------------------------------------*\
  #ANIMATION
\*------------------------------------*/

// Transtions
$transition1: 0.28s all;
$transition2: 0.38s all;
$transition3: 0.55s all;

/*------------------------------------*\
  #RESPONSIVE
\*------------------------------------*/

// Device widths
$mobile-width: 520px;
$tablet-width: 768px;
$between-tablet: 900px;
$desktop-width: 1024px;
