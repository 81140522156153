.c-list {
  li {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
    img {
      margin-right: 1rem;
    }

    span {
      font-size: 1.4rem;
    }
  }
}
