.c-dashboard {
  width: 104rem;
  margin: 5rem auto 8rem;

  @media screen and (max-width: 1400px) {
    width: 100%;
    margin: 3rem auto 8rem;
  }

  &__end-of-use{
    display: flex;
    justify-content: flex-end;
  }

  &__card {
    background-color: $clr-secondary;
    border-radius: 1rem;

    &--fill {
      flex: 1 1 0;
    }

    &--fixed-20 {
      flex-basis: 20%;
    }

    &--full-width {
      width: 100%;
    }

    &--flex-column {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
    }
  }

  // C_dashboard
  &__row {
    margin-bottom: 2rem;
  }

  &__group {
    display: flex;

    &--space-evenly {
      justify-content: space-evenly;
      gap: 2rem;


      @media screen and (max-width: 1100px) {
        flex-direction: column;
      }
    }
  }

  &__passport-groups {
    width: 100%;
    margin: 2rem 0;

    h2 {
      margin-bottom: 2rem;
    }
  }

  &__type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 550px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .c-dashboard__group {
      small {
        font-size: 1.4rem;

        @media screen and (max-width: 550px) {
          font-size: 1.7rem;
        }
      }

      span {
        font-size: 1.4rem;
        margin-bottom: 0;
        margin-left: 1rem;

        @media screen and (max-width: 550px) {
          margin-left: 0;
          margin-right: 1rem;
          font-size: 1.7rem;
        }

        &:nth-of-type(2) {
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }

  &__intro {
    display: flex;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    .c-dashboard__group {
      &:first-of-type {
        margin-right: 2rem;
        display: flex;
        width: calc(100% / 4 - 6rem / 4);
        min-width: calc(100% / 4 - 6rem / 4);

        @media screen and (max-width: 900px) {
          margin-right: 0 !important;
          margin-bottom: 2rem;
          width: 100%;
        }
      }
    }
  }

  &__primary {
    display: flex;
    margin-bottom: 2rem;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    &__info {
      margin-right: 2rem;
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;

      .c-passport__info {
        margin-bottom: 2rem;
      }
    }

    &__share {
      width: 32rem;
      min-width: 32rem;

      @media screen and (max-width: 900px) {
        width: 100%;
        min-width: 100%;
      }
    }

    .c-passport__public {
      display: flex;
      align-items: center;
      padding: 2.5rem 4rem;
      height: 100%;
      width: 100%;
    }

    .c-passport__progress {
      margin-bottom: 0;

      @media screen and (max-width: 900px) {
        margin-bottom: 2rem;
      }
    }
  }

  &__impact-group {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    .c-dashboard__group {
      width: calc(100% / 5 - (8rem / 5));
      min-width: calc(100% / 5 - (8rem / 5));
      margin-right: 2rem;
      height: 16rem;

      @media screen and (max-width: 900px) {
        width: 100%;
        height: auto;
        min-width: 100%;
        margin-bottom: 2rem;
      }

      &:last-of-type {
        margin-right: 0;

        @media screen and (max-width: 900px) {
          margin-bottom: 0;
        }
      }

      &--end-product {
        margin-bottom: 2rem;
        width: calc(100% / 3 - (4rem / 3));
        min-width: calc(100% / 3 - (4rem / 3));

        &:nth-child(3n) {
          margin-right: 0;
        }

        &:nth-last-child(-n+3) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__passports-composition {
    display: flex;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    .c-dashboard__group {
      width: calc(100% / 2 - 1rem);
      min-width: calc(100% / 2 - 1rem);
      margin-right: 2rem;

      @media screen and (max-width: 900px) {
        width: 100%;
        min-width: 100%;
        margin-right: 0;
      }

      &:last-of-type {
        margin-right: 0;

        @media screen and (max-width: 900px) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__passports {
    display: flex;
    flex-direction: column;

    .c-dashboard__passport {
      margin-bottom: 2rem;
      padding: 2rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__passports-row {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    .c-dashboard__group {
      width: calc(100% / 2 - 1rem);
      min-width: calc(100% / 2 - 1rem);
      margin-right: 2rem;

      @media screen and (max-width: 900px) {
        width: 100%;
        min-width: 100%;
        margin-right: 0;
        margin-bottom: 2rem;
      }

      &:last-of-type {
        margin-right: 0;

        @media screen and (max-width: 900px) {
          margin-bottom: 0;
        }
      }
    }

    .c-dashboard__card {
      width: 100%;
      padding: 2rem;
    }
  }

  &__composition {

    .c-dashboard__card {
      width: 100%;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 900px) {
        margin-top: 2rem;
      }

      h4 {
        text-align: center;
        font-weight: 600;
        font-size: 2rem;
        margin-bottom: 2rem;
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 17.5rem;

    img {
      max-width: 80%;
      max-height: 80%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    justify-content: center;

    h1 {
      font-size: 2.5rem;
      font-weight: 600;
    }

    p {
      font-size: 1.5rem;
    }
  }

  &__impact {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 6rem;
      max-height: 6rem;
      margin-bottom: 1rem;
    }

    h2 {
      font-size: 2.5rem;
      font-weight: 600;
      margin-bottom: 0;
    }

    span {
      font-size: 1.4rem;
    }
  }

  &__passport {
    display: flex;
    flex-direction: column;


    &__end-of-use {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &__labels {
        margin: 1.2rem;
        min-width: 100%;
        text-align: center;

        .text-color-highlight{
          color: $clr-tertiary;
        }

        span{
          margin: 0 .5rem;
        }
      }

    }


    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;

      h4 {
        margin-bottom: 0;
        font-size: 1.6rem;
        font-weight: 600;
      }

      small {
        font-size: 1.4rem;
      }
    }
  }

  &__sdg {
    display: flex;
    padding: 2rem;

    &__icons {
      display: flex;
      flex-wrap: wrap;
      max-width: 20rem;
      margin-right: 2rem;
      min-width: 20rem;

      img {
        height: 6rem;
        width: auto;
        max-width: 9999rem;
      }

    }

    &__text {
      display: flex;
      flex-direction: column;

      h2, h3, h4, h5, h6 {
        font-size: 1.9rem;
        font-weight: 600;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }
}

.c-passport {

  // C_passport
  .c-dashboard__row {
    display: flex;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    &:nth-of-type(1) {
      margin-bottom: 3rem;

      .c-dashboard__group {
        &:nth-of-type(1) {
          margin-right: 2rem;
          width: 100%;
          max-width: 100%;
          display: flex;
          flex-direction: column;
        }

        &:nth-of-type(2) {
          width: 28rem;
          min-width: 28rem;
          display: flex;
          flex-direction: column;

          @media screen and (max-width: 900px) {
            width: 100%;
            min-width: 100%;
          }
        }

        .c-passport__info, .c-passport__cta {
          margin-bottom: 3rem;
        }
      }
    }

    &:nth-of-type(2) {
      .c-dashboard__group {
        &:nth-of-type(1) {
          margin-right: 2rem;
          width: 28rem;
          min-width: 28rem;
          display: flex;
          flex-direction: column;

          @media screen and (max-width: 900px) {
            width: 100%;
            min-width: 100%;
            order: 2;
          }
        }

        &:nth-of-type(2) {
          width: 100%;
          display: flex;
          flex-direction: column;

          @media screen and (max-width: 900px) {
            order: 1;
            margin-bottom: 3rem;
          }
        }
      }
    }
  }

  &__info {
    &__intro {
      padding: 3rem 2.5rem 3rem 3.5rem;
      display: flex;
      align-items: center;

      @media screen and (max-width: 600px) {
        padding: 2rem;
        flex-direction: column;
        align-items: flex-start;
      }

      &__image {
        @include square-dimensions(14rem);
        border-radius: 1rem;
        border: 1px solid #D3E0E5;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 3.5rem;

        @media screen and (max-width: 600px) {
          @include square-dimensions(8rem);
          margin-bottom: 2rem;
        }

        img {
          max-width: 80%;
          max-height: 80%;
        }
      }

      &__text {
        display: flex;
        flex-direction: column;

        div {
          display: inline-flex;
          align-items: center;
          margin-bottom: 1rem;

          h1 {
            font-size: 3.3rem;
            margin-bottom: 0;
            margin-right: 1rem;

            @media screen and (max-width: 600px) {
              font-size: 2rem;
            }
          }

          div {
            margin-bottom: 0;
          }
        }

        p {
          font-size: 1.4rem;
          font-weight: 300;
          margin-bottom: 0;
        }

        &__trial-disclaimer {

          img {
            object-fit: contain;
            height: 3rem;
            width: auto;
            margin-right: 1rem;
          }

          p {
            font-weight: bold;
          }
        }
      }
    }

    &__blocks {
      display: flex;
      flex-direction: column;

      &__row {
        display: flex;
        width: 100%;
        border-top: 1px solid $clr-quaternary;

        @media screen and (max-width: 600px) {
          flex-direction: column;
          border-top: none;
        }

        .c-passport__info__blocks__single:first-of-type {
          border-left: none;
        }

        &:nth-of-type(1) {
          .c-passport__info__blocks__single {
            width: 50%;

            @media screen and (max-width: 600px) {
              width: 100%;
            }
          }
        }

        &:nth-of-type(2) {
          .c-passport__info__blocks__single {
            &:nth-of-type(1) {
              width: 50%;
            }

            &:nth-of-type(2) {

            }

            &:nth-of-type(3) {

            }

            &:nth-of-type(4) {

            }
          }
        }

        &--no-border {
          display: flex;
          width: 100%;

          @media screen and (max-width: 600px) {
            flex-direction: column;
          }

          .c-passport__info__blocks__single:first-of-type {
            border-left: none;
          }

          &:nth-of-type(1) {
            .c-passport__info__blocks__single {
              width: 50%;

              @media screen and (max-width: 600px) {
                width: 100%;
              }
            }
          }

          &:nth-of-type(2) {
            .c-passport__info__blocks__single {
              &:nth-of-type(1) {
                width: 50%;
              }
            }
          }
        }
      }

      &__single {
        display: flex;
        flex-direction: column;
        border-left: 1px solid $clr-quaternary;
        padding: 1.5rem 2rem;

        @media screen and (max-width: 600px) {
          width: 100%;
          border-left: none;
          border-top: 1px solid $clr-quaternary;
        }

        span {
          margin: 0;
          font-size: 1.4rem;

          &:nth-of-type(1) {
            font-weight: 500;
            color: $clr-tertiary;
          }

          &:nth-of-type(2) {
            font-weight: 300;
          }
        }
      }
    }
  }

  &__progress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;

    @media screen and (max-width: 900px) {
      margin-bottom: 3rem;
      align-items: start;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    &__indication {
      display: flex;
      align-items: center;
      margin-right: 2rem;

      @media screen and (max-width: 600px) {
        flex-direction: column;
        margin-right: 0;
        margin-bottom: 1rem;

        .c-card__single__score {
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }

      p {
        font-size: 1.4rem;
        font-weight: 300;
        max-width: 35rem;

        @media screen and (max-width: 600px) {
          text-align: center;
        }

        a {
          color: $clr-tertiary;
        }
      }

      &--public {
        display: flex;
        align-items: center;

        @media screen and (max-width: 600px) {
          margin-bottom: 0;
        }

        @media screen and (max-width: 900px) {
          align-items: start;
        }

        h2, h3, h4, h5, h6 {
          margin: 0 2rem 0 0;
          font-size: 1.6rem;
          font-weight: 600;
          white-space: nowrap;

          @media screen and (max-width: 600px) {
            margin: 0 0 1rem 0;
          }
        }

        p {
          max-width: 100%;
        }
      }
    }

    &__cta {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: right;

      span {
        margin-bottom: .25rem;
        white-space: nowrap;
        font-size: 1.3rem;
        color: $clr-tertiary;
      }

      a {
        span {
          color: $clr-secondary;
          margin: 0;
        }
      }
    }
  }

  &__cta {
    display: flex;
    flex-direction: column;

    &__single {
      padding: 1.5rem 1.5rem 1.5rem 2.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $clr-quaternary;

      &--no-padding {
        padding: 0;
      }

      &:last-of-type {
        border-bottom: none;
      }

      span {
        margin: 0;
        font-size: 1.4rem;
        font-weight: 400;
        text-decoration: underline;
      }

      div {

      }
    }
  }

  &__public {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 2.5rem 4rem;
    height: 100%;

    @media screen and (max-width: 900px) {
      align-items: start;
      padding: 2rem;
    }

    img {
      max-width: 13rem;
      margin-bottom: 3rem;
    }

    .btn--rounded {
      margin-bottom: 1rem;
    }

    .btn--text {
      display: block;

      span {
        font-size: 1.3rem;
        font-weight: 400;
      }
    }

    &--public {
      justify-content: center;
    }
  }

  &__connected {
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;

    .c-passport__connected__single--child {
      &:first-of-type {
        margin-top: 1rem;

        &::before {
          content: '';
          position: absolute;
          background: transparent;
          border-left: 1px solid $black-primary;
          border-bottom: 1px solid $black-primary;
          width: 1.5rem;
          height: 1.5rem;
          bottom: 1rem;
          left: -2.5rem;
        }
      }
    }

    h2, h3, h4, h5, h6 {
      font-size: 1.8rem;
      font-weight: 500;
      color: $clr-tertiary;
    }

    &__single {
      display: flex;
      align-items: center;
      margin-bottom: .5rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      img {
        margin-right: .5rem;
      }

      span {
        font-weight: 300;
        font-size: 1.4rem;
      }

      &--parent {
        margin-top: 0.5rem;

        span {
          font-weight: bold;
          font-size: 1.6rem;
        }
      }

      &--child {
        margin-top: 0.5rem;
        margin-left: 3rem;
        position: relative;
      }


      &--grandchild {
        margin-top: 0.5rem;
        margin-left: 6rem;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          background: transparent;
          border-left: 1px solid $black-primary;
          border-bottom: 1px solid $black-primary;
          width: 1.5rem;
          height: 1.5rem;
          bottom: 1rem;
          left: -2.5rem;
        }
      }
    }
  }

  &__sdg {
    padding: 2.5rem;
    height: 100%;

    h2, h3, h4, h5, h6 {
      font-size: 1.4rem;
      color: $clr-tertiary;
      font-weight: 600;
    }

    div {
      display: flex;
      flex-wrap: wrap;

      img {
        max-width: 10rem;
        width: calc(100% / 4);
      }
    }
  }

  &__labels {
    padding: 2.5rem;
    height: 100%;
    margin-top: 2rem;

    h2, h3, h4, h5, h6 {
      font-size: 1.4rem;
      margin-bottom: 2rem;
      color: $clr-tertiary;
      font-weight: 600;
    }

    div {
      display: flex;
      flex-wrap: wrap;

      img {
        max-width: 10rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        width: calc((100% / 3) - (1rem));
      }
    }

    p{
      margin-top: 1rem;
    }
  }

  &__tabs {
    height: 100%;

    &__header {
      display: flex;
      padding: 0 2.5rem;
      border-bottom: 1px solid $clr-quaternary;

      @media screen and (max-width: 900px) {
        flex-direction: column;
        height: auto !important;
        align-items: start;
        .text {
          padding: 1rem 0;
        }
      }

      button, a {
        margin-right: 2rem;
        padding: 1.8rem 0 1.7rem;
        font-size: 1.4rem;
        border-bottom: 3px solid transparent;
      }

      .isActive {
        border-bottom: 3px solid $clr-tertiary;
        font-weight: 600;
        color: $clr-tertiary;
      }
    }

    &__content {

      &__wrapper {
        display: flex;
        flex-direction: column;
        padding: 1rem 0;

        @media screen and (max-width: 900px) {
          padding: 2.5rem !important;
        }

        &--impact {
          padding: 3rem 0;
          flex-direction: row;
        }
      }

      &__single {
        padding: 1rem 2.5rem;
        display: flex;
        align-items: center;

        @media screen and (max-width: 900px) {
          padding: 0;
        }

        &--impact {
          flex-direction: column;
          align-items: center;
          width: 20%;
          padding: 1rem;
          text-align: center;
          justify-content: flex-start;

          span {
            padding: 0 !important;
            width: 100% !important;
          }

          img {
            margin: 2rem;
            height: 5rem;
          }
        }

        &--end-product {
          width: calc(100% / 3) !important;
          margin-bottom: 2rem;
        }


        span, a {
          margin: 0;
          width: 50%;
          font-size: 1.3rem;
          padding-right: 1rem;
        }

        a {
          text-decoration: underline;
        }
      }
    }

    &__scope-selector {
      width: 100%;
      height: 9rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 3rem;
      position: relative;

      &__title {
        width: 100%;
        text-align: center;
      }

      &__options {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      &__radio {
        display: inline-block;
        text-align: center;
        width: calc(100% / 3 - 1rem);

        input[type=radio] {
          position: relative;
          z-index: 2;
          background-color: #fff;
          margin: 0;
          appearance: none;
          color: white;
          height: 1.8em;
          width: 1.8em;
          box-shadow: 0 0 0 0.05em #7B7B7B;
          border-radius: 50%;

          &:checked {
            background-color: #0075FF;
            border: 0.25em solid white;
            box-shadow: 0 0 0 0.05em #0075FF;
          }

          &:hover {
            cursor: pointer;
          }

          &:focus {
            outline: none;
          }
        }

        label {
          display: block;
          font-size: 1.4rem;
        }
      }

      &::before {
        position: absolute;
        content: '';
        width: 80%;
        height: 2rem;
        border: 1px solid #000000;
        top: 3.8rem;
        left: 0;
        right: 0;
        margin-right: auto;
        margin-left: auto;
        background: linear-gradient(90deg, rgba(207, 14, 14, 1) 0%, rgba(219, 117, 21, 1) 28%, rgba(242, 238, 69, 1) 52%, rgba(227, 245, 44, 1) 72%, rgba(40, 166, 17, 1) 100%);
      }
    }
  }
}

.c-passport__footer {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;

  img {
    max-width: 7rem;
    margin-left: 1.5rem;
  }

  span {
    margin: 0;
    font-size: 1.3rem;
  }

}
