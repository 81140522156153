.c-free-trial-menu {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  align-items: center;
  background: #0E1427;
  color: #fff;

  &__left {
    ul {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        margin-right: 1.5rem;
        margin-left: 0;
      }
    }
  }

  &__right {
    a {
      margin-left: 1.5rem;
    }

    small {
      font-size: 1.5rem;
    }
  }
}