.c-free-trial-banner {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 2.5rem;
  align-items: center;
  background: #0E1427;
  color: #fff;
  border-radius: 3rem;
  margin-top: 2rem;

  p{
    color: #fff;
    flex: 1;
    margin: 0;
    font-weight: bold;
    padding-right: 1.5rem;
    font-size: 1.3rem;
  }


}