.c-message {
  background-color: #D3E0E5;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: .25rem;
    img {
      margin-right: .5rem;
    }
    h2,h3,h4,h5,h6 {
      color: $clr-primary;
      font-size: 1.4rem;
      font-weight: 500;
      margin: 0;
    }
  }
  p {
    font-size: 1.2rem;
  }
}
