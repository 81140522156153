.c-app {
  display: flex;

  &__menu {
    width: 27.5rem;
    min-width: 27.5rem;
    min-height: 100vh;
    padding: 4rem 0 4rem 4rem;
    transition: .3s all;

    @media screen and (max-width: 1100px) {
      position: fixed;
      top: 0;
      left: 0;
      background-color: $clr-secondary;
      z-index: 4;
      transform: translateX(-100%);
    }

    &__nav {
      ul {
        li {
          margin-bottom: 2rem;
          display: flex;

          a {
            font-size: 1.8rem;
            font-weight: 300;
          }

          .c-tag {
            margin-left: 1rem;
          }
        }

        .isActive {
          position: relative;
          opacity: 1;

          a {
            color: $clr-tertiary;
            font-weight: 500;

            &:after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              width: .5rem;
              height: 100%;
              background-color: $clr-tertiary;
            }
          }
        }
      }
    }
  }

  &__logo {
    display: flex;
    margin-bottom: 4rem;

    img {
      max-width: 15rem;
    }
  }

  &__content {
    width: 100%;
    min-height: 100vh;
    background-color: $clr-quaternary;
    padding: 4rem 4rem 12rem;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 550px) {
      padding: 4rem 3rem 12rem;
    }

    &__container {
      height: 100%;
    }

    &__empty {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
      }

      div {
        display: flex;
        align-items: center;
        @media screen and (max-width: 500px) {
          margin-top: 1.5rem;
        }

        a, button {
          margin-left: 1rem;

          @media screen and (max-width: 500px) {
            margin-left: 0;
            margin-right: 1rem;
          }
        }
      }

      &--public {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 104rem;
        margin: 0 auto;

        @media screen and (max-width: 1040px) {
          width: 100%;
        }
      }

      &__name {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 15rem;
          max-height: 8rem;

          @media screen and (max-width: 600px) {
            max-width: 10rem;
            max-height: 6rem;
          }
        }

        figure {
          width: 2px;
          background-color: #D3E0E5;
          height: 5rem;
          display: block;
          margin: 0 2rem;

          @media screen and (max-width: 600px) {
            height: 3rem;
          }
        }

        h1 {
          font-size: 2.2rem;
          margin: 0;
          font-weight: 600;

          @media screen and (max-width: 600px) {
            font-size: 1.7rem;
          }
        }
      }
    }
  }

  &__create {
    width: 92rem;
    max-width: 92rem;
    margin: 4rem auto 0;
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: 950px) {
      width: 100%;
      max-width: 100%;
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
      margin: 3rem auto 0;
    }

    &--start {
      flex-direction: column;
    }

    &__header {
      margin-bottom: 4rem;

      small {

      }

      h1 {

      }

      p {

      }
    }

    &__cards {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media screen and (max-width: 900px) {
        flex-direction: column;
      }

      &__single {
        width: 30rem;
        background-color: $clr-secondary;
        border: 2px solid #EBEBEB;
        border-radius: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4rem 3rem;

        @media screen and (max-width: 900px) {
          width: 100%;
          margin-bottom: 2rem;
        }
        @media screen and (max-width: 550px) {
          padding: 3rem;
        }

        img {
          width: 8.5rem;
        }

        h3 {
          margin: .5rem 0 1rem;
          font-weight: 600;
          font-size: 2.2rem;
        }

        p {
          font-size: 1.3rem;
          font-weight: 400;
          margin-bottom: 3rem;
        }

        div {
          width: 100%;
        }

        &:hover {
          border: 2px solid $clr-tertiary;

          .btn--blue {
            background-color: $clr-tertiary;

            span {
              color: $clr-secondary;
            }
          }
        }
      }
    }

    &__primary {
      width: 53.5rem;

      @media screen and (max-width: 900px) {
        order: 2;
        width: 100%;
      }
    }

    &__overview {
      width: 33.5rem;
      margin-left: 5rem;
      background-color: $clr-secondary;
      border-radius: 1rem;
      padding: 3rem;

      @media screen and (max-width: 900px) {
        order: 1;
        margin-left: 0;
        width: 100%;
        margin-bottom: 2rem;
        padding: 1rem 1rem 1rem 2rem;
        border: 1px solid $clr-primary;
      }

      header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
          font-size: 1.8rem;
          margin-bottom: 0;
        }

        img {
          margin-right: 1rem;
          display: none;

          @media screen and (max-width: 900px) {
            display: flex;
          }
        }
      }

      &__single {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        border-bottom: 1px solid #D3E0E5;
        padding-bottom: 1.5rem;
        margin: 1.5rem 0;

        @media screen and (max-width: 900px) {
          opacity: 0;
          padding: 0;
          margin: 0;
          height: 0;
        }

        &--top {
          align-items: flex-start;
        }

        &:last-of-type {
          padding-bottom: 0;
          margin: 1.5rem 0 0;
          border-bottom: none;
        }

        &__bar {
          width: 100%;
          background-color: $clr-quaternary;
          border-radius: 10rem;
          height: 1.8rem;
          padding: .5rem;
          display: flex;
          margin-top: .5rem;

          div {
            height: 100%;
            background-color: $clr-tertiary;
            border-radius: 10rem;
          }
        }

        div {
          width: 100%;

          h5 {
            font-size: 1.4rem;
            font-weight: 300;
            margin: 0;
            color: $clr-primary;
          }

          span {
            font-size: 1.4rem;
            font-weight: 400;
            color: $clr-tertiary;
          }

          small {
            font-size: 1.1rem;
            font-weight: 400;
            color: $clr-tertiary;
          }
        }

        button {
          margin-left: 2rem;
        }
      }

      &__save {

        @media screen and (max-width: 900px) {
          display: none;
        }

        button, a {
          width: 100%;
        }
      }
    }

    &__overview-container{
      display: flex;
      flex-direction: column;
      
      .c-notification--warning{
        width: 33.5rem;
        margin-left: 5rem;

        @media screen and (max-width: 900px) {
          margin-left: 0;
          width: 100%;
        }
      }
    }

    &__composition {
      margin-top: 2rem;


      h2 {
        font-size: 1.8rem;
        font-weight: 600;
      }
    }

    &__sdg {
      padding-top: 2rem;
      margin-top: 2rem;
      border-top: 1px solid #D3E0E5;

      h2 {
        font-size: 2.2rem;
        font-weight: 500;
      }

      &__container {
        display: flex;
        flex-wrap: wrap;
      }

      &__single {
        width: calc(100% / 4 - 3rem / 4);
        background-color: $clr-secondary;
        border-radius: 1rem;
        margin: 0 1rem 1rem 0;

        @media screen and (max-width: 450px) {
          width: calc(100% / 2 - .5rem);

          &:nth-of-type(even) {
            margin-right: 0;
          }
        }

        label {
          padding: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &:nth-of-type(4n+4) {
          margin: 0 0 1rem 0;
        }

        img {
          max-width: 8rem;
          margin-bottom: 1rem;
          border-radius: 1rem;
        }
      }
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #D3E0E5;

    p {
      margin: 0 1.5rem 0 0;
    }
  }

  &__filter {
    margin-bottom: 1rem;

    .c-form__row {
      display: flex;
      align-items: center;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    .c-form__group--horizontal {
      width: auto;
      margin-right: 1rem;

      @media screen and (max-width: 600px) {
        width: 100%;
        margin-right: 0;
      }

      input[type=text] {
        width: 35rem;

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }

      select {
        width: 20rem;

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }


    &--passport-group{
      .c-form__row{
        margin-top: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 950px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .c-form__group--horizontal{
          margin-right: 0;
        }
      }
    }
  }

  &__passport-group{
    margin: 4rem 0;

    &__content{
      background-color: #fff;
      padding: 2rem 3rem;
      margin-bottom: 1rem;
      border-radius: 1rem;

      h1{
        margin: 0;
        font-size: 3rem;

        @media screen and (max-width: 1023px) {
          font-size: 2.8rem;
        }

        @media screen and (max-width: 650px) {
          font-size: 2.5rem;
        }
      }


      &--steps{
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    &__step{
      opacity: 0.5;
      margin-right: 2rem;
      display: flex;
      align-items: center;

      &--pointer{
        cursor: pointer;
      }

      .btn{
        cursor: default!important;
        margin-right: 1rem;
        color: #fff;
        font-weight: bold;
        line-height: 1;
      }

      span{
        font-size: 1.8rem;
        font-weight: bold;
      }

      &.isActive{
        opacity: 1;
      }
    }
  }

  &__view {
    width: 92rem;
    max-width: 92rem;
    margin: 4rem auto 0;

    @media screen and (max-width: 950px) {
      width: 100%;
      max-width: 100%;
    }

    &__header {
      margin-bottom: 3rem;

      h1 {
        font-size: 3.5rem;

        @media screen and (max-width: 600px) {
          font-size: 2.5rem;
        }
        margin: 0;
      }
    }

    &__row{
      display: flex;
      justify-content: flex-end;
      margin: 0.4rem 0;
      align-items: center;

      span {
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }

      &__text-selection{
        display: flex;
        justify-content: center;
        margin-left: auto;

        .text-instruction-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.5rem;

          button{
            margin-right: 0;
          }
        }
      }


    }

  }

  &__tab {
    background-color: $clr-secondary;
    padding: 0 3rem;
    display: flex;
    align-items: center;
    border-radius: 1rem;
    margin-bottom: 2rem;

    &--passport-overview {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4rem;
    }

    @media screen and (max-width: 600px) {
      padding: 1rem 1rem 0;
      flex-wrap: wrap;
    }

    a, button {
      padding: 2rem 0 1.7rem;
      margin-right: 2rem;
      border-bottom: 3px solid transparent;

      @media screen and (max-width: 600px) {
        border: 1px solid $clr-primary;
        padding: 1rem;
        border-radius: 1rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
      }

    }

    .isActive {
      border-bottom: 3px solid $clr-tertiary;
      font-weight: 600;

      @media screen and (max-width: 600px) {
        background-color: $clr-tertiary;
        color: $clr-secondary;
        border: 1px solid $clr-tertiary;
      }
    }
  }

  &__info-list {
    background-color: $clr-secondary;
    padding: 2rem;
    display: flex;
    border-radius: 1rem;
    width: 100%;
    margin-bottom: 2rem;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    &__single {
      margin: 0 3rem 0 0;
      width: 50%;

      @media screen and (max-width: 900px) {
        width: 100%;
        margin: 0 0 2rem 0;

        &:last-of-type {
          margin: 0;
        }
      }

      h2 {
        color: $clr-primary;
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      div {
        display: flex;

        span {
          width: 50%;
          padding-right: 2rem;
          margin-bottom: 1rem;

          &:nth-of-type(1) {
            font-weight: 500;
          }
        }

        &:last-of-type {
          span {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__info-block {
    background-color: $clr-secondary;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    width: 100%;

    h2 {
      color: $clr-primary;
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    &__single {
      display: flex;
      background-color: $clr-quaternary;
      padding: 1rem;
      border-radius: 1rem;
      margin-bottom: 1rem;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      span {
        margin-right: 1rem;
        margin-bottom: 0;
      }
    }
  }

  &__info__users {
    &__single {
      background-color: $clr-secondary;
      padding: 2rem 3rem 2rem 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 1rem;
      width: 100%;
      margin-bottom: 1rem;

      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &__info {
        display: flex;
        flex-direction: column;

        div {
          display: flex;
          align-items: center;

          div {
            margin-right: .5rem;
          }
        }

        span {
          font-size: 1.8rem;
          font-weight: 500;
          margin-top: .5rem;
          margin-bottom: 0;
        }
      }

      &__cta {
        @media screen and (max-width: 600px) {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 1rem;
          padding-top: 1rem;
          border-top: 1px solid #D3E0E5;
          width: 100%;
        }

        a {
          margin-left: 1rem;

          @media screen and (max-width: 600px) {
            margin-left: 0;
            margin-right: 1rem;
          }
        }
      }
    }

    .isInactive {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  &__info-card {
    background-color: $clr-secondary;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    width: 100%;
    margin-bottom: 2rem;

    &--connected-passports {

      .c-form__row {
        flex-wrap: wrap;

        .c-form__group {
          width: calc(50% - .5rem);
        }

        .c-form__group:nth-of-type(even) {
          margin-right: 0;
        }

        .c-form__group:nth-of-type(odd) {
          margin-right: 1rem;
        }
      }


    }

    &__intro {
      margin-bottom: 2rem;

      h1, h2 {
        color: $clr-primary;
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: .5rem;
      }
    }

    .c-signup__info p {
      margin: 0;
    }

    .c-signup__info {
      h2 {
        font-size: 1.8rem;
      }

      &__single {
        span {
          font-size: 1.8rem;
        }
      }
    }

    &--60 {
      width: 60%;
      margin-right: 2rem;
    }

    &--40 {
      width: 40%;
    }

    &__footer {
      @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
      }

      a, button {
        margin-right: 1rem;

        @media screen and (max-width: 600px) {
          margin-right: 0;
          margin-top: 1.5rem;

          &:first-of-type {
            margin-top: 0;
          }
        }
      }
    }
  }

  &__info-card-wrap {
    display: flex;
  }
}

.overviewActive {
  .c-app__create__overview__single {
    padding-bottom: 1.5rem;
    margin: 1.5rem 0;
    opacity: 1;
    height: initial;
  }

  .c-app__create__overview__save {
    display: flex;
  }
}


.c-passport .c-dashboard__row:nth-of-type(2) .c-dashboard__group:last-child{
  margin-right: 0 !important;
  flex: 1;
}


.passport-select{

  .css-1pahdxg-control{
    box-shadow: none!important;
    border-color: hsl(0, 0%, 80%)!important;
  }

  .css-1s2u09g-control:hover{
    border-color: hsl(0, 0%, 80%)!important;
  }

  .css-1okebmr-indicatorSeparator, .css-26l3qy-menu{
    display: none;
  }

  .css-1hb7zxy-IndicatorsContainer div:last-child{
    display: none;

  }

  .css-1gtu0rj-indicatorContainer{
    color: hsl(0, 0%, 80%);
  }
}
