button {
  background: none;
  border: 0;

}

@import '0.defaults/defaults';
@import '1.objects/objects';
@import '2.components/components';
@import '3.utilities/utilities';
@import '4.themes/themes';
@import '5.scope/scope';
@import '6.is-has/is-has';
@import '7.hacks/hacks';
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.c-address {
  border-bottom: 1px solid #eee;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.c-signup .c-form .js-newAddress {
  margin-top: 0;

  &:hover {
    cursor: pointer;
  }
}

.js-removeAddress {
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
}

.c-signup__details__row {
  flex-wrap: wrap;

  .c-signup__details__single {
    margin-bottom: 2rem;
  }
}

.span-button {
  &:hover {
    cursor: pointer;
  }
}

.inputfile {

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  color: #000;
  border: 1px solid #D3E0E5;
  min-height: 5rem;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  background-color: #ffff;
  font-size: 1.25rem;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border-radius: .8rem;
  overflow: hidden;
  height: auto;
  padding: 1rem 1.25rem;

  margin-top: 2.5rem;
  width: 100%;

  small {
    min-width: 100%;
    display: flex;

  }

  .content {
    display: flex;
    align-items: center;
  }

  span {
    color: #E14343;
  }

  svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 1rem;
  }
}

.c-app__create__overview__single__bar div {
  transition: all linear .2s;
}

.c-pagination {
  ul {
    display: flex;
    list-style-type: none;
  }

  .previous, .next {
    display: none;
  }
}

.c-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

}

.btn, a {
  &:hover {
    cursor: pointer;
  }
}

html {
  scroll-behavior: smooth;
}


.c-form__group input[type=checkbox] {
  margin-right: .5rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.pdf-container {
  iframe {
    width: 100%;
    height: 110rem;
  }
}


.c-dashboard__passport ul {
  padding-left: 1.5rem;

  li {
    margin-bottom: 0 !important;
  }
}

.c-dashboard__passport span {
  font-size: 1.2rem;
  line-height: 2.5rem;
}

.legenda {
  flex: 1;
}

.c-dashboard__type .c-dashboard__group span:nth-of-type(2) {
  &:hover {
    cursor: pointer;
  }
}


.c-signup__examples {

  &-item {

    padding: 2rem;
    border: 1px solid #eee;
    margin-bottom: 2rem;

    header {
      display: flex;
      justify-content: space-between;
      margin: 0;
      align-items: center;
      margin-bottom: 2rem;

      .left {
        width: 10rem;
        padding: 2rem;
      }

      .right {
        p {
          margin: 0;
        }
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        margin-bottom: 0;
      }

      .left {
        flex: 1;
        padding-right: 5rem;
      }

      .c-example-data {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          margin-bottom: .5rem;
        }
      }
    }


  }

}

.c-dashboard__card__tabs {
  display: flex;

  > button {
    width: 50%;
    text-align: center;
    border: 0;
    padding: 2rem;
    background: none;

    &.isActive {
      color: #E14343;
    }

    &:first-child {
      border-right: 1px solid #d2d2d2;
    }
  }
}

.c-dashboard-passport__material-list {
  ul {
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;

      span {
        font-size: 1.5rem;
      }

      img {
        width: 2rem;
        margin-right: 1rem;
      }
    }
  }
}

.c-delete-input {
  position: absolute;
  top: 1.2rem;
  z-index: 5;
  right: -1rem;
  background: #E14343;
  color: #fff;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  line-height: 2rem;
  text-align: center;
}

.c-visible-input {
  position: absolute;
  top: 1.2rem;
  z-index: 5;
  right: 2rem;
  background: #0E1427;
  color: #fff;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  line-height: 2rem;
  text-align: center;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 2rem;
    height: auto;

    path {
      fill: #fff;
    }
  }
}

.inputfile a {
  white-space: pre-wrap;
  overflow: hidden;
}

.c-loader--full img {
  animation-name: rotate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .c-dashboard__card {

    > div {
      flex-wrap: wrap;
      justify-content: center;

      .c-pie-chart {
        width: 90% !important;
      }
    }
  }
}

.c-passport__tabs__content__wrapper {
  flex-wrap: wrap;
  justify-content: center;

  .c-pie-chart {
    width: 90% !important;
    max-width: 30rem;
  }
}

.c-files {
  .c-passport__tabs__content__single {
    display: block;
    width: 100%;

    > * {
      display: block;
      width: 100%;
    }
  }
}

.c-passport__tabs__content__wrapper {
  flex-wrap: wrap;
  justify-content: center;

  .c-pie-chart {
    width: 90% !important;
    max-width: 30rem;
  }
}

.ql-toolbar.ql-snow .ql-formats span {
  margin: 0 !important;
}

.ql-toolbar.ql-snow .ql-formats {
  margin: 0 15px 0 0 !important;
}

.ql-editor {
  height: 20rem;
}

.c-passport__public .btn--text span {
  display: inline-block;
}

.is-multiple {
  button {
    left: 0;
    position: relative;
    height: 2rem;
    padding: 0;
    line-height: 1.2rem;
    top: 0;
    bottom: 0;
    right: 0;
    width: 2rem!important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: .5rem;

    svg{
      width: 1.5rem;
      height: auto;
    }
  }
}

.c-files {
  .c-passport__tabs__content__single {
    display: block;
    width: 100%;

    > * {
      display: block;
      width: 100%;

    }
  }
}

.is-loading {
  opacity: .5;
  pointer-events: none;
}

.c-alert {
  background: #ff728a;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 1rem;
  position: relative;
  top: -0.4rem;
  color: #fff;
  left: 0.5rem;
}

.c-disclaimer-line a, .c-disclaimers a {
  color: #E14343;
}

.c-dashboard-passport__material-list {
  .c-disclaimers {
    list-style-type: none;
    padding: 0;

    li {
      text-align: center;
      margin-top: 1.5rem;
      font-style: italic;
      min-width: 100%;
      padding: 0 2rem;

      span {
        font-size: 1rem;
        line-height: 1.2;
        margin-left: .5rem;
      }
    }
  }
}
