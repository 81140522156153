/*------------------------------------*\
  #Form
\*------------------------------------*/

.c-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &--black {
    input, select, textarea {
      color: $clr-primary !important;

      &:focus {
        border: 1px solid $clr-primary !important;
      }
    }
  }

  .btn--large {
    width: 100%;
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: #D3E0E5;
    margin-bottom: 1.5rem;
  }

  &__subject {
    margin: 2.5rem 0 2rem;

    h2 {
      font-size: 2.2rem;
      margin: 0;
      font-weight: 500;
    }

    &--small {
      margin-top: 1rem;
      h2 {
        font-size: 1.8rem;
      }
    }

    &--border {
      border-bottom: 1px solid #D3E0E5;
      display: flex;
      width: 100%;
      padding: 0 0 1rem;
      h2 {
      }
    }
  }
  &__row {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;

    @media screen and (max-width: 600px) {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &--block {
      flex-direction: column;
      background-color: #D3E0E5;
      padding: 2rem;
      border-radius: 1rem;

      @media screen and (max-width: 600px) {
        margin-bottom: 1rem;
      }
    }
    &--block-small {
      flex-direction: column;
      background-color: #D3E0E5;
      padding: 1rem 2rem;
      border-radius: 1rem;

      @media screen and (max-width: 600px) {
        margin-bottom: 1rem;
      }
    }

    &--half {
      display: flex;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }

      .c-form__group {
        width: 50%;

        @media screen and (max-width: 600px) {
          width: 100%;
          margin-bottom: 1.5rem;
        }

        &:first-of-type {
          margin-right: 1rem;

          @media screen and (max-width: 600px) {
            margin-right: 0;
          }
        }
      }
    }
    &--three {
      display: flex;

      .c-form__group {
        width: calc(100% / 3 - 2rem / 3);

        &:nth-of-type(2) {
          margin: 0 1rem;
        }
      }
    }
    &--name {
      display: flex;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
      .c-form__group:nth-of-type(1), .c-form__group:nth-of-type(3) {
        width: 40%;
        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
      .c-form__group:nth-of-type(2) {
        width: 20%;
        margin: 0 1.5rem;
        @media screen and (max-width: 600px) {
          width: 100%;
          margin: 0 0 1.5rem;
        }
      }
    }
    &--address {
      display: flex;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
      .c-form__group:nth-of-type(1) {
        width: 60%;

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
      .c-form__group:nth-of-type(2) {
        margin: 0 1rem;

        @media screen and (max-width: 600px) {
          margin: 0 0 1.5rem;
        }
      }
      .c-form__group:nth-of-type(2), .c-form__group:nth-of-type(3) {
        width: 20%;

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
    &--radio {
      display: flex;
      flex-wrap: wrap;

      .c-form__group {
        margin-right: 2rem;
        width: auto;

        .radio-label {
          margin: 0 0 .5rem;
          font-weight: 500;
        }

        label {
          display: flex;
          align-items: center;
          input {
            margin-right: .5rem;
          }
          span {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  &__group {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 600px) {
      margin-bottom: 1.5rem;
    }

    &--horizontal {
      flex-direction: row;
      align-items: center;
      width: 100%;
      label {
        margin-right: 1rem;
        margin-bottom: 0 !important;
      }
      input {
        width: 10rem;
      }
    }

    .c-form__label-button {
      position: relative;
      display: block;
      align-items: center;
      span {
        font-size: 1.5rem;
        margin: 0;
      }
    }

    label {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: .5rem;
      position: relative;

      button {
        position: relative;
        width: auto;

        img {
          position: relative;
          top: initial;
          left: initial;
          right: initial;
          bottom: initial;
        }
      }
    }

    input[type=text],  input[type=url], input[type=file], input[type=password], input[type=email], input[type=tel], input[type=number], select {
      height: 5rem;
      border-radius: 1rem;
      border: 1px solid #D3E0E5;
      padding: .25rem 1.25rem;
      font-size: 1.5rem;
      color: $clr-tertiary;
      font-weight: 400;
      background-color: $clr-secondary;

      &:focus {
        outline: none;
        border: 1px solid $clr-tertiary;
      }
      &:disabled {
        background-color: transparent;

        &:hover {
          cursor: not-allowed;
        }
      }
    }

    input[type=checkbox] {
      height: auto;
      width: auto;
      appearance: checkbox;
      -webkit-appearance: checkbox;
      -ms-progress-appearance: checkbox;
      -moz-appearance: checkbox;
      cursor: pointer;
    }

    textarea {
      width: 100%;
      resize: vertical;
      font-size: 1.5rem;
      border-radius: 1rem;
      border: 1px solid #D3E0E5;
      padding: 1rem 1.25rem;
      min-height: 10rem;
      color: $clr-tertiary;
      font-weight: 400;

      &:focus {
        outline: none;
        border: 1px solid $clr-tertiary;
      }
    }

    select {
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-progress-appearance: none;

      &:focus {
        outline: none;
        border: 1px solid $clr-tertiary;
      }
    }

    &--checkbox {
    }

    &--button {
    }
    &--inline {
      width: auto;
    }
  }
  &__select {
    position: relative;

    select {
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-progress-appearance: none;
      height: 5rem;
      min-height: 5rem;
      border-radius: .6rem;
      padding: .25rem 2.5rem .25rem 1rem;
      font-size: 1.4rem;
      font-weight: 400;
      min-width: 6rem;
    }

    img, small {
      position: absolute;
      right: 1.5rem;
      bottom: 2rem;
      margin: auto;
      font-size: .7rem;
    }
    small {
      font-size: 1.5rem;
      font-weight: 400;
      top: 4.25rem;
      line-height: 1;
      right: 1.5rem;
      bottom: 0;
    }

    &--smaller {
      display: flex;
      width: 15rem;
      margin-left: 1rem;
      align-items: center;
      justify-content: center;

      select {
        height: 4rem;
        min-height: 4rem;
        padding: .25rem 3rem .25rem 1.5rem;
        font-size: 1.4rem;
        font-weight: 400;
        min-width: 4rem;
      }

      img, small {
        position: absolute;
        right: 1.5rem;
        bottom: 1.5rem;
        margin: 0;
        font-size: .2rem;
      }
      small {
        font-size: 1rem;
        font-weight: 300;
        //top: 4.25rem;
        line-height: 1;
        right: 1.5rem;
        bottom: 0;
      }
      //label{
      //  font-size: 1.2rem;
      //  font-weight: 400;
      //  margin-bottom: .5rem;
      //  //position: relative;
      //}

    }
  }
}
