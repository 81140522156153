.c-info-popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  opacity: 0;
  pointer-events: none;

  &__close-btn {
    border-radius: 50%;
    border: none;
    background-color: $clr-secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    @include square-dimensions(4rem);
    position: absolute;
    top: 1.5rem;
    left: 42.5rem;
    z-index: 6;
    transform: translateX(-100%);
    transition: .5s all;
    opacity: 0;

    @media screen and (max-width: 600px) {
      left: 85%;
    }

    img {
      width: 2rem;
    }

  }

  &__bg {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    pointer-events: none;
    opacity: 0;
  }
  &__content {
    background-color: $clr-quaternary;
    width: 100%;
    max-width: 45rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    height: 100%;
    overflow-x: auto;
    padding: 5rem 3rem;
    transform: translateX(-100%);
    transition: .5s all;
    opacity: 0;

    @media screen and (max-width: 600px) {
      max-width: 90%;
    }

    &__image {
      border-radius: 1rem;
      margin-bottom: 1rem;
      max-width: 100%;
      max-height: 22rem;
    }

    h1 {
      font-size: 2.75rem;
    }
    h2 {
      font-size: 2.4rem;
    }
    h3 {
      font-size: 2.2rem;
    }
    h4 {
      font-size: 2rem;
    }
    h5,h6 {
      font-size: 1.7rem;
    }
    p,ul li, ol li {
      font-size: 1.5rem;
    }

    &__footer {
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 1px solid #c6d8dc;
    }
  }

  &__button {
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    @include square-dimensions(2.2rem);
    background-color: $clr-primary;
    margin: 0 .5rem;

    small {
      text-align: center;
      color: $clr-secondary;
      font-size: 1.6rem;
      font-family: serif;
      font-weight: 600;
    }

    &--small {
      @include square-dimensions(1.5rem);

      small {
        font-size: 1.2rem;
      }
    }
    &--large {
      @include square-dimensions(3rem);

      small {
        font-size: 2rem;
      }
    }
    &--red {
      background-color: $clr-tertiary;
    }
    &--outline-dark {
      border: 2px solid $clr-primary;
      background-color: transparent;

      small {
        color: $clr-primary;
      }
    }
  }
}

.popupActive {
  pointer-events: all;
  opacity: 1;
  .c-info-popup__bg {
    opacity: 1;
    pointer-events: all;
  }
  .c-info-popup__close-btn, .c-info-popup__content {
    transform: translateX(0);
    opacity: 1;
  }
}
