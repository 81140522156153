.c-collapse {
  &__single {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 2rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid #D3E0E5;

      @media screen and (max-width: 400px) {
        flex-direction: column;
        align-items: flex-start;
      }

      h2 {
        font-size: 1.8rem;
        font-weight: 600;
        color: $clr-primary;
        margin: 0;
      }
      button {
        background-color: #D3E0E5;
        display: flex;
        align-items: center;
        border-radius: 10rem;
        border: none;
        padding: .5rem 1rem;

        @media screen and (max-width: 400px) {
          margin-top: 1rem;
        }

        span {
          margin: 0 1rem 0 0;
        }
      }
    }
    &__content {

    }
  }
  .isActive {
    border-bottom: 1px solid transparent;
    padding-bottom: 0;

    h2 {
      color: $clr-tertiary;
    }

    img {
      transform: rotate(180deg);
    }
  }
}
