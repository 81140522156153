
.c-language-picker-container {
  position: fixed;
  right: 1rem;
  top: 0;
  z-index: 9999;
  min-width: 10rem;
  display: flex;
  width: auto;
  align-items: flex-start;


  .c-language-picker__notice {
    background: #272727;
    height: 2rem;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;

    p {

      display: inline-block;
      text-transform: uppercase;
      font-size: .7rem;
      letter-spacing: .3rem;
      color: #fff;
    }
  }


  .c-language-picker {

    &__language {
      padding: .4rem 1.2rem;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      background: #fff;
      border-top: 1px solid #eee;
      height: 2rem;

      &:hover {
        cursor: pointer;
      }

      &--selected {
        background: #E14343;
        border-top: 0;

        span {
          color: #fff;
        }
      }

      span {
        display: inline-block;
        margin: 0 .7rem 0 0;
        text-transform: uppercase;
        font-size: .7rem;
        letter-spacing: .3rem;
      }

      img {
        height: 1rem;
        width: auto;
      }
    }
  }
}