.c-text {
  display: flex;
  flex-direction: column;
  &--center {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  &--small {
    max-width: 56rem;
  }
}
