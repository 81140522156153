.c-breadcrumbs {
  ul {
    display: flex;

    li {
      a, button {
        font-size: 1.4rem;
        font-weight: 400;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      &:last-of-type {
        a, button {
          pointer-events: none;
        }

        img {
          display: none;
        }
      }

      img {
        margin: 0 1.2rem;
        font-size: 1rem;
      }
    }
  }
}
