.c-assistant {
  position: relative;
  min-width: 50rem;

  @media screen and (max-width: 1100px) {
    min-height: 30rem !important;
    min-width: auto;
  }

  &__step-instruction {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 6rem;
  }

  &__steps {
    position: absolute;
    inset: auto 2rem 3rem 2rem;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between !important;

    &__step {
      background-color: $blue-primary;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      text-align: center;
      display: flex;
      position: relative;
      transition: .5s all;
      box-shadow: 1px 1px 6px 0px rgb(0 0 0 / 32%);

      img {
        display: none;
      }

      span {
        margin: auto;
      }

      &--current {
        position: relative;

        span {
          z-index: 2;
          color: $clr-secondary;
        }

        &:before {
          position: absolute;
          border-radius: 50%;
          content: "";
          background-color: $clr-primary;
          height: 2.2rem;
          width: 2.2rem;
          top: 0.4rem;
          left: 0.4rem;
        }
      }

      &--completed {
        background-color: $clr-tertiary;

        img {
          display: inline-block;
          width: 1rem;
          height: auto;
          margin-left: auto;
          margin-right: auto;
          filter: brightness(0) invert(1);
        }

        span {
          display: none;
        }
      }
    }

    &__bar {
      background-color: $blue-primary;
      position: absolute;
      height: .5rem;
      opacity: .5;
      left: 1rem;
      right: 0;
      margin: 0;
      overflow: hidden;

      &:before {
        content: "";
        position: absolute;
        background-color: $clr-tertiary;
        width: 0;
        top: 0;
        left: -1rem;
        bottom: 0;
        height: 100%;
        transition: .5s all;
      }

      &--filled-25 {
        &:before {
          width: 25%;
        }
      }

      &--filled-50 {
        &:before {
          width: 50%;
        }
      }

      &--filled-75 {
        &:before {
          width: 75%;
        }
      }

      &--filled-100 {
        &:before {
          width: 100%;
        }
      }
    }
  }
}
