.c-signup {
  padding: 6rem 0;

  @media screen and (max-width: 768px) {
    padding: 0 0 10rem;
  }

  &__progress-bar{
    height: .5rem;
    margin: 2rem 0;
    width: 100%;
    background: #eee;
    position: relative;


    span{
      transition: all ease-in-out .4s;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: #0E1427;
    }
  }

  &--upgrade {
    .o-container {
      max-width: 66rem;
      padding: 3rem;
      background: #fff;
    }
  }

  .o-container {
    .btn--large {
      width: 100%;
    }
  }

  &__logo {
    margin: 0 auto 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 23rem;
  }

  &__step {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__edit {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &__single {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100% / 3);
      position: relative;

      .span-button{
        margin-left: .2rem;
        transform: translateY(-.6rem);
        z-index: 4;
        padding: 0.4rem .8rem;
        color: #E14343;
        text-decoration: none !important;
        border-radius: 1rem;
      }

      &:nth-of-type(2) {
        margin: .25rem 0;
      }

      span {
        margin: 0;
        font-size: 1.2rem;
      }

      i{
        margin-right: 1.2rem;
        font-size: 1rem;
        width: 1.6rem;
        height: 1.6rem;
        background: #E14343;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }

      img {
        margin-right: .5rem;
      }

      a {
        text-decoration: underline;
        margin-left: .25rem;
        font-weight: 300;
        width: 4rem;
        text-align: right;
      }
    }
  }

  &__intro {
    margin-bottom: 3rem;

    h1 {
      margin-bottom: .5rem;
    }

    span {
      margin: 0;
    }
  }

  &__info {
    &__single {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5rem;

      @media screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: flex-start;
      }

      div {
        margin-right: 2rem;

        h2 {
          color: $clr-primary;
          font-size: 2.2rem;
          font-weight: 400;
          margin: 0;
        }
      }

      .c-counter {
        margin-right: 0;

        @media screen and (max-width: 600px) {
          justify-content: flex-start;
          margin-top: 1.5rem;
        }
      }

      span {
        font-size: 2.2rem;
        font-weight: 500;
        margin: 0;
      }

      small {
        color: $clr-tertiary;
        font-size: 1.4rem;
        font-weight: 400;
      }

      &--line {
        border-top: 1px solid #D3E0E5;
        padding-top: 2rem;
        margin-top: 1rem;
      }
    }

    p {
      margin-bottom: 5rem;
    }
  }

  &__details {

    &__row {
      display: flex;
      margin-bottom: 2rem;

      @media screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 0;
      }

    }

    &__single {
      width: 50%;
      padding-right: 2rem;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 600px) {
        padding-right: 0;
        padding-bottom: 2rem;
      }

      h2 {
        font-size: 2rem;
        font-weight: 400;
        color: $clr-primary;
        margin: 0 0 .5rem;
      }

      span {
        margin: 0;
      }
    }
  }

  &__confirm {
    border-top: 1px solid #D3E0E5;
    padding-top: 2rem;
    margin-top: 2rem;

    .btn {
      margin-top: 2rem;
    }
  }

  .c-form {
    .js-newAddress {
      margin: 2rem 0 3rem;
    }
  }
}
