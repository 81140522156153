.c-auth {
  width: 100%;
  height: 100vh;
  display: flex;

  &__primary {
    width: 47.5rem;
    min-width: 47.5rem;
    height: 100vh;
    padding: 8rem 8rem 4rem;

    @media screen and (max-width: 900px) {
      width: 100%;
      min-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media screen and (max-width: 600px) {
      padding: 4rem;
    }

    &__container {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 900px) {
        max-width: 47.5rem;
      }
    }

    &__title {
      margin-bottom: .5rem;
      font-size: 2.8rem;
    }

    .btn {
      width: 100%;
      margin-top: 5rem;
    }
  }
  &__media {
    width: 100%;
    height: 100vh;

    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  &__logo {
    margin-bottom: 8rem;
    max-width: 23rem;
  }

  &__form {
    margin-top: 2rem;
    .btn {
      margin-top: 0;
    }
    .btn--text {
      span {
        font-weight: 400 !important;
      }
    }
  }
}
