.c-counter {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    @include square-dimensions(4rem);
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
    span {
      text-align: center;
      font-size: 3rem;
      margin: 0;
      padding: 0;
      font-weight: 700;
    }
    &:nth-of-type(1) {
      background-color: $clr-secondary;
      span {
        color: $clr-primary;
      }
    }
    &:nth-of-type(2) {
      background-color: $clr-primary;
      span {
        color: $clr-secondary;
      }
    }
  }
  input {
    width: 5rem;
    height: 4rem;
    border: none;
    padding: 0 1rem;
    margin: 0 1rem;
    border-radius: .5rem;
    font-weight: 600;
    text-align: center;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
