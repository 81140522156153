.c-cockpit {
  background: #E14343;
  margin-left: -4rem;
  margin-top: -4rem;
  margin-right: -4rem;
  min-height: 100vh;
  margin-bottom: -12rem;
  overflow: hidden;

  .c-app__content__container {
    max-width: 124rem;
    margin: 0 auto;
  }

  .c-dashboard {
    width: auto;
    margin: 5rem 5rem 8rem 5rem;
  }

  .c-dashboard__card {
    position: relative;

    padding: 2rem;
    box-shadow: 1px 1px 6px 0px rgb(0 0 0 / 32%);


    p {
      font-size: 1.2rem;
    }

    &-v2 {
      background: #fff;
      border-radius: 1rem;
      flex: 1 1;
      padding: 0 3rem 4rem 3rem;
      box-shadow: 1px 1px 6px 0px rgb(0 0 0 / 32%);
      position: relative;


      &__add {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background: #cecaca;
        height: 3rem;
        width: 3rem;
        text-align: center;
        line-height: 2.8rem;
        font-weight: bold;
        color: #fff;
        border-radius: 1rem;
        transition: all ease-in-out .3s;

        &:hover {
          cursor: pointer;
          background: #E14343;
        }

      }


      &__count {
        font-size: 4rem;
        font-weight: bold;
      }

      h3 {
        font-size: 1.5rem;
      }

      img {
        height: 5rem;
        width: 5rem;
        margin-top: 3rem;
      }

      p {
        font-size: 1rem;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      padding: 2rem;
    }
  }

  .btn.btn--rounded.btn--red {
    position: relative;

    span {
      @media screen and (max-width: 1580px) {
        font-size: 1.3rem;
      }
    }
  }

  .c-dashboard__card__title {
    left: 0;
    right: 0;
    font-size: 1.6rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: bold;
    color: #000;
  }

  .c-app__view {
    margin-left: 0;
  }

  .c-assistant {
    .btn.btn--rounded.btn--red {
      position: relative;
      bottom: 0;
      margin-right: 1rem;
    }

    .c-dashboard__card__title {
      position: static;
      margin-left: 0;
      text-align: start;

      @media screen and (max-width: 1100px) {
        margin-bottom: 3rem;
      }
    }
  }

  &__card-title {
    width: 100%;
    margin-bottom: 2rem;
    font-size: 2rem;

    &--margin-small {
      margin-bottom: 0.4em;
    }
  }

  &__card-text {
    padding-bottom: 2rem;
  }

  &__card-icon {
    height: auto;
    width: 5rem;
  }

  &__count {
    width: 4rem;
    height: 4rem;
    border-radius: 1rem;
    background-color: #0E1427;
    transition: all 0.3s ease-in-out;

    span {
      color: #ffffff;
      font-weight: bold;
      font-size: 1.4rem;
      margin-top: auto;
      margin-bottom: auto;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  &__card-background {
    opacity: 1;
    height: 100%;
    width: 60%;
    margin: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 0;
    right: 0;

    @media screen and (max-width: 1100px) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__passport-intro {
    max-width: 40rem;

    a {
      color: #0E1427;
    }

    @media screen and (max-width: 1100px) {
      max-width: 100%;
    }

    &__disclaimer {
      display: flex;
      margin-bottom: 3rem;

      span {
        font-weight: bold;
      }

      img {
        vertical-align: middle;
        width: 3rem;
        min-width: 3rem;
        margin-right: 1rem;
      }
    }

    ol {
      list-style: none;
      counter-reset: item;
      padding: 0;

      li {
        counter-increment: item;
        margin-bottom: 1rem;
        position: relative;
        padding-left: 3rem;
      }

      li:before {
        left: 0;
        position: absolute;
        content: counter(item);
        background: $clr-primary;
        border-radius: 50%;
        color: white;
        width: 1.3em;
        text-align: center;
      }
    }
  }

  &__sdg-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: start !important;

    &__sdg {
      width: calc((100% / 12) - (2rem * (11 / 12)));
      height: auto;
      border-radius: 1rem;
      box-shadow: 1px 1px 6px 0px rgb(0 0 0 / 32%);


      @media screen and (max-width: 1100px) {
        width: calc((100% / 8) - (2rem * (7 / 8)));
      }

      @media screen and (max-width: 600px) {
        width: calc((100% / 3) - (2rem * (2 / 3)));
      }
    }
  }
}
