.c-card {
  &__single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $clr-secondary;
    padding: 2rem 2.5rem;
    border-radius: 1rem;
    margin-bottom: 1rem;

    @media screen and (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &__image {
      @include square-dimensions(7.4rem);
      border-radius: 1rem;
      border: 1px solid #D3E0E5;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 80%;
        max-height: 80%;
      }
    }
    &__info {
      display: flex;
      align-items: center;
      margin-right: 2rem;

      &__text {
        display: flex;
        flex-direction: column;
        margin-left: 2rem;
        padding-top: .25rem;

        h3 {
          font-size: 2.5rem;
          font-weight: 400;
          margin: 0 0 .5rem;

          @media screen and (max-width: 500px) {
            font-size: 2rem;
          }
        }
        span {
          font-size: 1.4rem;
          font-weight: 300;
        }
      }
    }
    &__score {
      @include square-dimensions(6rem);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $clr-secondary;
      border: .5rem solid #D3E0E5;
      position: relative;
      overflow: hidden;
      margin-right: 2rem;

      figure {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: $clr-quaternary;
      }

      span {
        position: relative;
        font-size: 1.7rem;
        font-weight: 600;
        color: $clr-tertiary;
        z-index: 1;
        margin: 0;
      }
    }
    &__cta {
      display: flex;
      align-items: center;

      @media screen and (max-width: 800px) {
        justify-content: space-between;
        width: 100%;
        padding-top: 1rem;
        margin-top: 1rem;
        border-top: 1px solid #D3E0E5;
      }

      &__urls {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        div {
          display: flex;
          margin-top: 1rem;
          a {
            height: 3.1rem;
            min-height: 3.1rem;
            padding: 0 1.5rem;
            margin-left: 1rem;
            align-items: center;
          }
          .btn--circle {
            @include square-dimensions(3.1rem);
            padding: 0;
          }
        }
      }
      &__external {
        img {

        }
        span {
          font-size: 1.4rem;
          text-decoration: underline;
          margin-left: .5rem;
        }
      }
    }

    &--small {
      padding: .6rem 1rem .6rem 2rem;
      @media screen and (max-width: 800px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      @media screen and (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .c-card__single__info__text {
        margin-left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        h3 {
          margin-right: 1rem;
          font-size: 1.8rem;
        }
      }
      .c-card__single__cta {
        @media screen and (max-width: 800px) {
          justify-content: space-between;
          padding-top: 0;
          margin-top: 0;
          border-top: none;
          width: auto;
        }
        @media screen and (max-width: 500px) {
          padding-top: 1rem;
          margin-top: 1rem;
          border-top: 1px solid #D3E0E5;
          width: 100%;
          a {
            margin-left: 0;
            margin-right: 1rem;
          }
        }
      }
      .c-card__single__cta__urls div {
        margin-top: 0;

        .btn--text {
          height: auto;
          padding: 0;

          span {
            font-size: 1.4rem;
            font-weight: 500;
          }
        }
      }
    }

    &--inActive{
      opacity: 0.5;
      cursor: default!important;
      pointer-events: none;

      h3{
        color: #000;
      }

      .btn{
        background-color: hsl(0, 0%, 90%);
        cursor: default!important;
        pointer-events: none;

        span{
          color: #000;
        }
      }
    }
  }
}
