/*------------------------------------*\
  #STANDARD BUTTON
\*------------------------------------*/

.btn {
  padding: 1rem 2rem;
  text-align: center;
  border: none;
  display: inline-flex;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -1rem;
    bottom: 0;
    width: 0;
    height: 100%;
    transition: .5s all;
  }

  span {
    font-weight: 700;
    font-size: 1.6rem;
    text-align: center;
    margin: 0;
  }

  &--info {
    border: none;
    padding: 0;
    margin: 0;
  }

  &--margin {
    margin-top: 2rem;
  }

  &--width {
    width: 100%;
  }

  &--large {
    padding: 1.7rem 2rem;

    span {
      font-size: 1.8rem;
    }
  }

  &--small {
    padding: 1rem 1.5rem;

    span {
      font-size: 1.4rem;
    }
  }

  &--outline {
    background-color: transparent;
    border: 2px solid $clr-primary;
  }

  &--circle {
    @include square-dimensions(3rem);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    img {
      z-index: 1;
    }
  }

  &--red {
    background-color: $clr-tertiary;

    span {
      position: relative;
      z-index: 5;
      color: $clr-secondary;
    }

    &:before {
      background-color: $clr-primary;
    }
    &:hover{
      color: $clr-secondary;

      &:before {
        width: 100%;
        left: 0;
      }
    }
  }
  &--black {
    background-color: $clr-primary;

    span {
      color: $clr-secondary;
    }
  }
  &--blue {
    background-color: $clr-quaternary;

    span {
      color: $clr-primary;
    }
  }

  &--rounded {
    border-radius: 50rem;
  }

  &--text {
    border: none;
    padding: 0;
    text-decoration: underline;
    font-weight: 300;

    &--margin-right-2 {
      margin-right: 2rem;
    }
  }

  &--disabled {
    background-color: rgba(203, 203, 205, 0.6);
    position: relative;

    &:hover{
      .btn--disabled__cloud{
        opacity: 1;
      }
    }

    span {
      color: #eae6e6;
      margin: 0;
    }

    &__cloud {
      transition: ease-in-out .4s;
      pointer-events: none;
      opacity: 0;
      position: absolute;
      z-index: 2;
      bottom: -12rem;
      width: 20rem;
      border-radius: 1rem;
      padding: 1rem;
      font-size: 1.3rem;
      background-color: #E14343;
      display: none;

      span {
        margin: 0;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: -1rem;
        left: 8rem;
        width: 3rem;
        height: 3rem;
        background-color: #E14343;
        transform: rotate(45deg) translateZ(-1px);
      }
    }

    //&:before {
    //  pointer-events: none;
    //  opacity: 0;
    //  position: absolute;
    //  z-index: 1;
    //  bottom: -10rem;
    //  width: 20rem;
    //  height: 7rem;
    //  border-radius: 1rem;
    //  padding: 1rem;
    //  font-size: 1.3rem;
    //  background-color: #E14343;
    //  content: 'You have created the maximum amount of passports during this trial period. Please complete your account to create more passports.';
    //}
    //
    //&:after {
    //  pointer-events: none;
    //  opacity: 0;
    //  content: '';
    //  position: absolute;
    //  left: auto;
    //  right: auto;
    //  top: 8rem;
    //  width: 3rem;
    //  height: 3rem;
    //  background-color: #E14343;
    //  transform: rotate(45deg);
    //}
    //
    //&:hover {
    //
    //  &:before {
    //    pointer-events: all;
    //    opacity: 1;
    //  }
    //
    //  &:after {
    //    top: 3.5rem;
    //    pointer-events: all;
    //    opacity: 1;
    //  }
    //}
  }
}
