.c-header-menu {
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  border-radius: 0;
  background-color: $clr-secondary;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 75px;
  display: none;

  @media screen and (max-width: 1100px) {
    display: flex;
    z-index: 4;
  }

  &__logo {
    display: flex;
    img {
      max-width: 14rem;
    }
  }
  &__trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    height: 100%;
    span {
      font-weight: 500;
      font-size: 16px;
      margin: 0 5px 0 0;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      figure {
        width: 20px;
        height: 3px;
        background-color: $clr-tertiary;
        transition: .3s all;
        &:nth-of-type(2) {
          margin: 3px 0;
        }
      }
    }
  }
}

.c-menu-bg {
  transition: .3s all;
}

.menuActive {
  .c-app__menu {
    transform: translateY(0);
    box-shadow: 5px 0px 20px 10px rgba(0,0,0,0.1);
  }
  .c-menu-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index: 3;
    opacity: 0;
    pointer-events: none;
  }
  .c-menu-bg {
    opacity: 1;
    pointer-events: all;
  }
  .c-header-menu__trigger {
    div {
      figure {
        &:nth-of-type(1) {
          transform: rotate(45deg);
        }
        &:nth-of-type(3) {
          transform: rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
          margin: -3px 0;
        }
      }
    }
  }
}
